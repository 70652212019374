<template>
  <div style="background-color: #f4f6f9">
    <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
               {{ $t('employee_details') }}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
            >
              <Button
              :label="$t('back')" 
              v-tooltip.bottom="$t('back')" 
                @click="Goback"
                size="small"
                style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="card1" class="card d-flex justify-content-center">
      <div class="col-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText id="name" type="text" 
                @keypress="validateNameInput"
              v-model="product.name" />
              <label for="name">{{ $t('employee_name') }}</label>
            </span>
          </div>
          <div class="field col-12 md:col-6" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText id="email_id" type="text"
                         
                v-model="product.email_id"/>
              <label for="email_id">{{ $t('email') }}</label>
            </span>
          </div>

          <div class="field col-12 md:col-3" style="margin-top: 1%" v-if="!product._id">
                  <span class="p-float-label">


                    <Password id="password" v-model="product.password"  :toggleMask="true" :feedback="false"
                                class="w-full mb-3"  autocomplete="off" 
                                @input="validatePassword"
                                 inputClass="w-full" :inputStyle="{ padding: '0.75rem' }"
                                ></Password>
                     
                      <label for="password">{{$t('password')}}</label>
                  </span>
                  <div v-if="passwordError" class="text-red-500 text-sm mb-3">
        {{ passwordError }}
    </div>
              </div>

              <div class="field col-12 md:col-3" style="margin-top: 1%" v-if="!product._id">
                  <span class="p-float-label">

                    <Password id="conf_password" v-model="product.conf_password"  :toggleMask="true" :feedback="false"
                                class="w-full mb-2" 
                                  @input="validatePassword"
                                 autocomplete="off" inputClass="w-full" :inputStyle="{ padding: '0.75rem' }"
                                ></Password>
                    
                      <label for="conf_password">{{ $t('conf_pass') }}</label>
                  </span>
                  <div v-if="passError" class="text-red-500 text-sm mb-3">
        {{ passError }}
    </div>
              </div>

        
        
          <div class="field col-12 md:col-3" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText
                id="mobile_number"
                type="text"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                maxlength="10"
                v-model="product.mobile_number"
              />
              <label for="mobile_number">{{ $t('mobile') }}</label>
            </span>
          </div>
       
          <!-- <div class="field col-12 md:col-3" style="margin-top: 1%">
            <span class="p-float-label">
              <MultiSelect
                  id="client_dropdown"
                  v-model="client_selected"
                  :options="client_dropdown"
                  :value="client_dropdown"
                  optionLabel="name"
                  filter
                ></MultiSelect>
              <label for="client">{{$t('client')}}</label>
            </span>
          </div> -->

          <div class="field col-12 md:col-3" style="margin-top: 1%">
            <!-- <span class="p-float-label">
              <InputText id="Gender" type="text" v-model="product.Gender" />
              <label for="Gender">Gender</label>
            </span>  -->
           
                 <div class="flex flex-wrap gap-3" style="margin-top: 3%">
                  <div class="flex align-items-center">
                    <label for="gender" class=" mb-2">{{ $t('select_gen') }}</label>
                </div>
                    <div class="flex align-items-center">
                        <RadioButton v-model="product.gender" inputId="ingredient1" name="Male" value="Male" ></RadioButton>
                        <label for="ingredient1" class="ml-2">{{ $t('male') }}</label> &nbsp;
                        <RadioButton v-model="product.gender" inputId="ingredient2" name="Female" value="Female" />
                        <label for="ingredient2" class="ml-2">{{ $t('female') }}</label>
                    </div>
                    
                    
                </div>

               

     
          </div>
          <div class="field col-12 md:col-2" style="margin-top: 1%">
            <FileUpload
              class="p-button-primary"
              mode="basic"
              name="model[]"
              :auto="true"
              :custom-upload="true"
               :choose-label="$t('choose_image')"
              @uploader="handleFileUpload($event)"
              style="
                box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                border-color: #00b0b0 !important;
                background-color: #00b0b0 !important;
              "
            />
          </div>
          <div class="field col-12 md:col-3" style="margin-top: 1%">
            <img
              :src="product.profile_image"
              :alt="product.profile_image"
              v-if="product.profile_image"
              class="shadow-2"
              width="70"
            />
            <!-- <img
              v-else
              src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
              class="shadow-2"
              width="70"
            /> -->
          </div>
          <div class="field col-12 md:col-12">
            <h6 style="color: #6b719b; font-size: 15px; font-weight: 400">
              {{ $t('about') }} :
            </h6>
            <Editor v-model="product.about" editorStyle="height: 200px" />
          </div>
        </div>
      </div>
      <div class="col-12 md:col-12 d-flex text-center justify-content-center">
        <Button
          class="p-button-primary"
          @click="submit()"
         :label="$t('submit')"
          style="
            box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
            border-color: #00b0b0 !important;
            background-color: #00b0b0 !important;
          "
        />
      </div>
    </div>
    <ScrollTop />
  </div>
</template>
  <script>
import apis from "@/apis";
import axios from "axios";
export default {
  name: "EticketCustomerCreateTicket",
  data() {
    return {
      add_edit:'Add',
      passwordError:"",
      passError:"",
      isLoadingModel: false,
      fullPage: true,
      product: [],
      attachments: "",
      client_id: "",
      file_data: {
                  filePath: "",
                  contentType: "",
                  
                  },
      client_dropdown:[],
      client_selected:"",
    };
  },
  async mounted() {
    this.client_id = localStorage.getItem("client_id");
    this.client_id = "65a4f82f6177e69880ece5d6";
    await this.getClientMaster();
    var local_data = [];
    this.product = [];
    if (localStorage.getItem("AgentDetails")) 
    {  
      local_data = await JSON.parse(localStorage.getItem("AgentDetails"));
      this.product = local_data;
      this.attachments=this.product.profile_image;
     
    
      await localStorage.setItem("AgentDetails", "");
      this.add_edit='Edit';
     
    }
  },
  watch:{
        
        currentLocale(){

     
   
    }
  },
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
  
  methods: {
   
    validatePassword() {
      
        this.passwordError = '';
        this.passError = '';

   
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        if (!passwordRegex.test(this.product.password)) {
            this.passwordError = "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
            return false;
        }

   
        if (this.product.password !== this.product.conf_password) {
            this.passError = "Password and Confirm Password do not match.";
            return false;
        }

        // Check if confirm password is empty
        if (this.product.conf_password === '' || this.product.conf_password === undefined) {
            if (!this.product._id) {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Please confirm the password",
                    life: 3000,
                });
                return false;
            }
        }

        // All validations passed, no errors
        return true;
    },


    validateNameInput(event) {
            const charCode = event.charCode || event.keyCode;
            if (
                (charCode >= 65 && charCode <= 90) ||  // Uppercase letters
                (charCode >= 97 && charCode <= 122) || // Lowercase letters
                charCode === 32 // Space
            ) {
                return true;
            }
            event.preventDefault(); // Prevent non-alphabet characters
        },
    disableAction(event) {
      event.preventDefault();
    },
    async getClientMaster() {
        var data = {
            limit: 10000,
            page_no: 1,
            count: false,
            user_id: localStorage.getItem("id"),
        };
      
        var promise = apis.getclientist(data);
        promise.then((response) => {
        this.client_dropdown = response.data.data;
        console.log("clients",this.client_dropdown);
        });
      },
    async submit()
    {   
      var name_regex = /^\s*$/;
         var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

        var mobile_reg=/^[0-9]{10}$/
        if (this.product.name=='' || this.product.name==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Enter Employee Name",
              life: 3000,
          });
          return false;
        }

        if (this.product.name) 
            {   
                if (name_regex.test(this.product.name)) 
                {
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        detail: "Please Enter Valid Employee Name",
                        life: 3000,
                    });
                    return false;
                }                
            }

        if (this.product.email_id=='' || this.product.email_id==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Enter Email ID",
              life: 3000,
          });
          return false;
        }

        if (this.product.email_id) 
        {   
            if (!emailreg.test(this.product.email_id)) 
            {
                    this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Valid Email ID",
                    life: 3000,
                });
                return false;
            }                
        }





// if ((this.product.password == '' || this.product.password == undefined) && !this.product._id) {   
//     this.$toast.add({
//         severity: "error",
//         summary: "Error",
//         detail: "Please Enter a password",
//         life: 3000,
//     });
//     return false;
// }
// if ((this.product.conf_password == '' || this.product.conf_password == undefined) && !this.product._id) {   
//     this.$toast.add({
//         severity: "error",
//         summary: "Error",
//         detail: "Please confirm the password",
//         life: 3000,
//     });
//     return false;
// }


//          this.passwordError = '';
//           this.passError = '';

   
//         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
//         if (!passwordRegex.test(this.product.password)) {
//             this.passwordError = "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
//             return false;
//         }

   
//         if (this.product.password !== this.product.conf_password) {
//             this.passError = "Password and Confirm Password do not match.";
//             return false;
//         }

      
//         if (this.product.conf_password === '' || this.product.conf_password === undefined) {
//             if (!this.product._id) {
//                 this.$toast.add({
//                     severity: "error",
//                     summary: "Error",
//                     detail: "Please confirm the password",
//                     life: 3000,
//                 });
//                 return false;
//             }
//         }

      



        if (this.product.mobile_number=='' || this.product.mobile_number==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please enter mobile number",
              life: 3000,
          });
          return false;
        }

        if (this.product.mobile_number) 
            {   
              if (!mobile_reg.test(this.product.mobile_number)) 
              {
                      this.$toast.add({
                      severity: "error",
                      summary: "error",
                      detail: "Please Enter Valid Mobile Number",
                      life: 3000,
                  });
                  return false;
              }                
            }

            if (/^0+$/.test(this.product.mobile_number) || /^0/.test(this.product.mobile_number)) {
          
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Mobile number cannot start with zero or contain all zeros.",
            life: 3000,
          });
          return false;
        }
       


        if (this.product.gender=='' || this.product.gender==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Select Gender",
              life: 3000,
          });
          return false;
        }

        if (this.product.about=='' || this.product.about==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Enter about Employee",
              life: 3000,
          });
          return false;
        }

        // if (this.client_selected=='' || this.client_selected==undefined) 
        // {   
        //   this.$toast.add({
        //       severity: "error",
        //       summary: "error",
        //       detail: "Please Select Client",
        //       life: 3000,
        //   });
        //   return false;
        // }
        
        this.$confirm.require({
          target: event.currentTarget,
          message: this.$t('confirm_action'),
          icon: "pi pi-exclamation-triangle ",
          acceptLabel: this.$t('yes'),
          rejectLabel: this.$t('no'),
          accept: () => {
            
            //edit
            if (this.product._id) 
            {
                var payload = 
                {   _id:this.product._id,
                    user_id: this.product._id,
                    client_id: localStorage.getItem("client_id"),
                    name:this.product.name,
                    email_id:this.product.email_id,
                    gender:this.product.gender,
                    mobile_number:this.product.mobile_number,
                    profile_image:this.attachments,
                    user_type:'Agent',
                    about:this.product.about,
                    password:this.product.password,
                    lang:this.$i18n.locale,
                };
                    this.isLoadingModel = true;
                    var promise = apis.updateUserProfile(payload);
                    promise.then((response) => {
                    this.isLoadingModel = false;
                    if (response.data.status == true) {
                        this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                        this.reset_form();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        this.Goback();
                    } else {
                        this.$toast.add({
                        severity: "error",
                        summary: "ERROR!!!",
                        detail: response.data.message,
                        life: 3000,
                        });
                    }
                    });
            
            }
            //add
            else
            {              
                var selected_client_id=[];
                if(this.client_selected)
                {
                  for (let index = 0; index < this.client_selected.length; index++) {
                    selected_client_id.push(this.client_selected[index]['_id']);
                  }
                }

                console.log("client ids",selected_client_id);
                var payload = 
                {
                    user_id: localStorage.getItem("id"),
                    client_id: localStorage.getItem("client_id"),
                    name:this.product.name,
                    email_id:this.product.email_id,
                    gender:this.product.gender,
                    mobile_number:this.product.mobile_number,
                    profile_image:this.attachments,
                    user_type:'Agent',
                    about:this.product.about,
                    password:this.product.password,
                    selected_clients:selected_client_id,
                  
                    lang:this.$i18n.locale

                };console.log("payload",payload);
                    this.isLoadingModel = true;
                    var promise = apis.regUser(payload);
                    promise.then((response) => {
                    this.isLoadingModel = false;
                    if (response.data.status == true) {
                        this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                        this.reset_form();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        this.Goback();
                    } else {
                        this.$toast.add({
                        severity: "error",
                        summary: "ERROR!!!",
                        detail: response.data.message,
                        life: 3000,
                        });
                    }
                    });
            }
            
          
        
        
        },
          reject: () => {
            this.$toast.add({
              severity: "error",
              summary: "Rejected",
              detail: "You have rejected",
              life: 3000,
            });
          },
        });
      
    },
    async Goback() {
      //this.$router.go(-1);
      this.$router.push({ name: "agents" });
    },
    async reset_form() {
      this.file_data = {
        filePath: "",
        contentType: "",
      };
      this.attachments = "";
    },
    async date_time_prefix() {
        // Get the current date and time
        const currentDateTime = new Date();
        // Format the date as YYYY_MM_DD
        const formattedDate = currentDateTime
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "_");
        // Format the time as HH_mm_ss
        const formattedTime = currentDateTime
          .toTimeString()
          .split(" ")[0]
          .replace(/:/g, "_");
        // Construct the final string
        const resultString = `${formattedDate}_${formattedTime}`;
        return resultString;
      },
    async handleFileUpload(event) {
      var fileUp = event.files[0];
      var file = fileUp;

      var validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        this.$toast.add({
          severity: "error",
          summary: "Invalid File",
          detail: "Only image files are allowed.",
          life: 3000,
        });
        return; // Stop the upload process
      }
      this.file = file;
      var prfeix = await this.date_time_prefix();
      var filename = prfeix + "_" + file.name;
      this.file_data = {
        filePath: filename,
        contentType: file.type,
      };
      var promise = apis.upload_to_AWS(this.file_data);
      promise.then((response) => {
        axios
          .put(response.data.data, file, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": file.type,
            },
          })
          .then((response) => {
            this.attachments =
              "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename;
              this.product.profile_image =  this.attachments;
          });
      });
    },
  },
};
</script>
  <style scoped>
#card1 {
  background-color: #fff;
  border-top: 3px solid #6b719b;
}
.p-confirm-dialog .pi.pi-exclamation-triangle {
  color: red !important;
}
.link-style {
  color: #6b719b;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 640px) {
  .responsive-gender {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.link-style:hover {
  text-decoration: underline; /* Add underline on hover */
}
@media screen and (min-width: 992px) {
}
</style>